import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import InfoContainer from '../../../../../components/InfoContainer';
import './index.scss';

const LastLogins = ({ user }) => {
  const history = useHistory();

  if (!user) return null;

  const onViewAll = () => {
    history.push(`/users-legal/${user.wallet}/logins`, {
      pathname: window.location.pathname,
    });
  };

  const hasLogins = user.logins?.length > 0;

  return (
    <InfoContainer
      title="LAST LOGINS"
      sideLink={
        hasLogins ? (
          <button onClick={onViewAll} className="link" type="button">
            View all
          </button>
        ) : null
      }
      className="notes"
    >
      <div className="login-container">
        <div className="login-item">
          <div className="login-item-date-label">Date</div>
          <div className="login-item-address-label">Address</div>
          <div className="login-item-country-label">Country</div>
        </div>
        {!hasLogins && (
          <div className="no-logins-found">
            <div className="no-logins-found-text">No logins found</div>
          </div>
        )}
        {user.logins?.map((item) => {
          return (
            <div key={item.id} className="login-record">
              <div className="login-record-date">
                {moment(item.lastLogin).format('DD-MMM-YYYY')}
              </div>
              <div className="login-record-address">{item.ipAddress}</div>
              <div className="login-record-country">{item.countryName}</div>
            </div>
          );
        })}
      </div>
    </InfoContainer>
  );
};

export default LastLogins;
