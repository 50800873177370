import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@material-ui/core';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';
import PoliticallyExposedPerson from './components/PoliticallyExposedPerson';
import PrimaryIntention from './components/PrimaryIntention';
import SourceOfFunds from './components/SourceOfFunds';
import { getInitialFormData, isFormValid } from './formHelpers';

function AdditionalInfo({ data, onUpdate }) {
  const [initialState, setInitialState] = useState({});
  const [formState, setFormState] = useState({
    isPep: '',
    primaryIntentions: '',
    otherIntentions: '',
    sourceOfFunds: '',
    otherSourceOfFunds: '',
    errors: {
      isPep: false,
      primaryIntentions: false,
      sourceOfFunds: false,
    },
  });

  useEffect(() => {
    if (!data) return;

    const initialData = getInitialFormData(data);

    setFormState((prevState) => ({
      ...prevState,
      ...initialData,
    }));

    setInitialState(initialData);
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: false,
      },
    }));
  };

  const validateForm = () => {
    const newErrors = isFormValid(formState);

    setFormState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));

    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const formData = {
      isPep: formState.isPep,
      primaryIntentions: formState.primaryIntentions,
      sourceOfFunds: formState.sourceOfFunds,
    };

    if (formState.primaryIntentions === 'other') {
      formData.primaryIntentions = formState.otherIntentions;
    }

    if (formState.sourceOfFunds === 'other') {
      formData.sourceOfFunds = formState.otherSourceOfFunds;
    }

    onUpdate(formData);
  };

  const isFormDirty = useMemo(() => {
    return Object.keys(formState).some((key) => {
      if (formState[key] === undefined || initialState[key] === undefined) {
        return false;
      }

      return formState[key] !== initialState[key];
    });
  }, [formState, initialState]);

  return (
    <Box component="form" className="additional-info">
      <p className="additional-info-header">
        Additional Info <span style={{ color: '#676767' }}>(required)</span>
      </p>
      <div className="profile-col-box align-start">
        <PoliticallyExposedPerson
          value={formState.isPep}
          error={formState.errors.isPep}
          onChange={handleInputChange}
        />
        <PrimaryIntention
          value={formState.primaryIntentions}
          otherValue={formState.otherIntentions}
          error={formState.errors.primaryIntentions}
          onChange={handleInputChange}
        />
        <SourceOfFunds
          value={formState.sourceOfFunds}
          otherValue={formState.otherSourceOfFunds}
          error={formState.errors.sourceOfFunds}
          onChange={handleInputChange}
        />
        <RoundedButton
          type="primary"
          buttonType="submit"
          className="update-button"
          onClick={handleSubmit}
          disabled={!isFormDirty}
        >
          Update
        </RoundedButton>
      </div>
    </Box>
  );
}

export default AdditionalInfo;
