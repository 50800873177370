import { FundSources, PrimaryIntentions } from './components/constants';

function getInitialFormData(data) {
  const initialData = {
    isPep: data.isPep,
    primaryIntentions: data.primaryIntentions,
    sourceOfFunds: data.sourceOfFunds,
  };

  if (data.primaryIntentions && !PrimaryIntentions.includes(data.primaryIntentions)) {
    initialData.primaryIntentions = 'other';
    initialData.otherIntentions = data.primaryIntentions;
  }

  if (data.sourceOfFunds && !FundSources.includes(data.sourceOfFunds)) {
    initialData.sourceOfFunds = 'other';
    initialData.otherSourceOfFunds = data.sourceOfFunds;
  }

  return initialData;
}

function isFormValid(formState) {
  const newErrors = {
    isPep: !formState.isPep,
    primaryIntentions: !formState.primaryIntentions && formState.primaryIntentions !== 'other',
    sourceOfFunds: !formState.sourceOfFunds && formState.sourceOfFunds !== 'other',
  };

  if (formState.primaryIntentions === 'other' && !formState.otherIntentions) {
    newErrors.primaryIntentions = true;
  }
  if (formState.sourceOfFunds === 'other' && !formState.otherSourceOfFunds) {
    newErrors.sourceOfFunds = true;
  }

  return newErrors;
}

export { getInitialFormData, isFormValid };
