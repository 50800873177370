import { getAvailableClaimAmountFromClaimer } from 'contracts/claimerContract';
import { getTokenInfo } from 'contracts/erc20';
import { BigNumber, ethers } from 'ethers';
import { XDAI_CHAIN_ID } from 'constants/config';
import { getProviderByChainId } from 'contracts/providers';

export const formatClaimAmount = (claimAmounts, tokenInfo) => {
  const claimAmountBN = BigNumber.from(claimAmounts.toString());

  const one = ethers.utils.parseUnits('1', tokenInfo.decimals);

  if (claimAmountBN.lt(one)) {
    return '0.0';
  }

  return ethers.utils.formatUnits(claimAmountBN, tokenInfo.decimals);
};

const getClaimerClaimAmountsForToken = async (
  provider,
  claimerAddress,
  tokenAddress,
  walletAddress,
  dealContributionAmount
) => {
  try {
    const claimAmounts = await getAvailableClaimAmountFromClaimer(
      provider,
      claimerAddress,
      walletAddress,
      dealContributionAmount,
      tokenAddress
    );

    const tokenInfo = await getTokenInfo(provider, tokenAddress);

    return {
      claimAmount: formatClaimAmount(claimAmounts, tokenInfo),
      token: {
        address: tokenAddress,
        ...tokenInfo,
      },
    };
  } catch (error) {
    return { claimAmount: '0.0', token: null };
  }
};

export const getClaimerData = async (claimers, walletAddress, contributedAmount) => {
  return await Promise.all(
    claimers.map(async (claimer) => {
      delete claimer.claimableAmountCalls;

      if (!claimer.tokens.length && claimer.chainId !== +XDAI_CHAIN_ID) {
        return { ...claimer, claimableAmounts: [], token: null };
      }

      const provider = getProviderByChainId(claimer.chainId);

      const claimableAmountsPromises = claimer.tokens.map(({ address }) =>
        getClaimerClaimAmountsForToken(
          provider,
          claimer.address,
          address,
          walletAddress,
          contributedAmount
        )
      );

      return {
        ...claimer,
        claimableAmounts: await Promise.all(claimableAmountsPromises),
      };
    })
  );
};
