import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const PoliticallyExposedPerson = ({ value, error, onChange }) => (
  <FormControl error={error}>
    <p className="profile-col-title">Are you a Politically Exposed Person?</p>
    <div className="radio-group">
      <div style={{ width: '100%' }}>
        <RadioGroup aria-label="politically-exposed" name="isPep" value={value} onChange={onChange}>
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
        {error && <FormHelperText>This field is required.</FormHelperText>}
      </div>
    </div>
  </FormControl>
);

export default PoliticallyExposedPerson;
