import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import RoundedButton from 'components/button/rounded-button';
import Tabs from 'components/tabs';
import './index.scss';
import useTabs from './hooks/useTabs';

const LegalDetails = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { params } = useRouteMatch();

  const history = useHistory();
  const goBack = () => {
    history.push(history.location.state.pathname, { activeTab: history.location.state.activeTab });
  };

  const tabs = useTabs(params.wallet);

  return (
    <div className="all-deals-page">
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">
            <span className="title-span">Users</span>/ {params.wallet}
          </div>
        </div>

        <RoundedButton className="back-button" type="primary" onClick={goBack}>
          Go back
        </RoundedButton>
      </div>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    </div>
  );
};

export default LegalDetails;
